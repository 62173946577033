import React from 'react';
import LogoImg from '../assets/images/little_transparent.png';
import { Box, Typography } from '@mui/material';

const Logo = () => {
    return (
        <Box display={'flex'}>
            <Box component="img" src={LogoImg} width="50px" alt="S5P"></Box>
            <Typography sx={{ pl: '10px', alignItems: 'center' }} variant="h3">
                S5P
            </Typography>
        </Box>
    );
};

export default Logo;
