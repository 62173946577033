import * as React from 'react';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import backgroundImage from '../../../assets/images/hero.png';
import backgroundImageSmall from '../../../assets/images/hero_sm.png';
import { Table, TableBody, TableCell, TableRow, Typography } from '@mui/material';

const ProductHeroLayout = styled('section')(({ theme }) => ({
    color: theme.palette.common.white,
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    minHeight: 300,
    [theme.breakpoints.up('sm')]: {
        minHeight: 600
    },
    [theme.breakpoints.up('md')]: {
        alignItems: 'end'
    },
    [theme.breakpoints.up('lg')]: {
        minHeight: 800
    },
    [theme.breakpoints.up('xl')]: {
        minHeight: 800
    }
}));

const Background = styled(Box)(({ theme }) => ({
    color: theme.palette.common.white,
    backgroundColor: '#112538',
    position: 'absolute',
    backgroundRepeat: 'no-repeat',
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
    backgroundSize: 'cover',
    zIndex: -2,
    [theme.breakpoints.up('lg')]: {
        minHeight: 900,
        backgroundImage: 'url(' + backgroundImage + ')'
    },
    [theme.breakpoints.up('xl')]: {
        top: '-60px'
    }
}));

const BoxImage = styled(Box)(({}) => ({
    alt: 'S5P',
    width: '100%'
}));

const BoxImageSmall = styled(BoxImage)(({ theme }) => ({
    [theme.breakpoints.up('md')]: {
        display: 'none'
    }
}));

const BoxImageLarge = styled(BoxImage)(({ theme }) => ({
    display: 'none',
    [theme.breakpoints.up('md')]: {
        display: 'flex'
    },
    [theme.breakpoints.up('lg')]: {
        display: 'none'
    }
}));

const ListBox = styled(Box)(({ theme }) => ({
    paddingTop: '20px',
    paddingBottom: '20px',
    paddingLeft: '20px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    [theme.breakpoints.up('md')]: {
        padding: 0,
        position: 'absolute',
        top: '-10%',
        left: '65%'
    },
    [theme.breakpoints.up('lg')]: {
        top: 0,
        left: '60%'
    },
    [theme.breakpoints.up('xl')]: {
        top: 0,
        left: '70%'
    }
}));

const Superscript = styled(Typography)(({}) => ({
    fontSize: '0.7rem',
    borderBottom: 'none',
    padding: 0,
    verticalAlign: 'top'
}));

const ListTableCellRight = styled(TableCell)(({ theme }) => ({
    display: 'flex',
    fontSize: '1.5rem',
    color: theme.palette.common.white,
    borderBottom: 'none',
    padding: 0,
    verticalAlign: 'center',
    [theme.breakpoints.up('lg')]: {
        fontSize: '2rem'
    }
}));

const Title = styled(Box)(({ theme }) => ({
    fontSize: '2rem',
    color: theme.palette.common.white,
    borderBottom: 'none',
    padding: 0,
    width: '100%',
    justifyContent: 'center',
    [theme.breakpoints.up('lg')]: {
        fontSize: '2.5rem'
    }
}));

function ProductHero() {
    return (
        <ProductHeroLayout>
            <Title sx={{ display: 'flex', py: 3, px: 2, justifyItems: 'center' }}>
                <Box sx={{ display: 'block' }}>
                    <Typography sx={{ textAlign: 'center', pt: 2, pb: 1 }} variant="h2">
                        Code Your SaaS App.&nbsp;{' '}
                    </Typography>
                    <Typography sx={{ textAlign: 'center' }} variant="h4">
                        Don&apos;t Code Boilerplate.{' '}
                    </Typography>
                </Box>
            </Title>
            <BoxImageSmall component={'img'} src={backgroundImageSmall}></BoxImageSmall>
            <BoxImageLarge component={'img'} src={backgroundImage}></BoxImageLarge>

            <ListBox sx={{ width: '180px', height: '100%' }}>
                <Table>
                    <TableBody>
                        <TableRow>
                            <ListTableCellRight>
                                S<Superscript>1</Superscript>calable
                            </ListTableCellRight>
                        </TableRow>
                        <TableRow>
                            <ListTableCellRight>
                                S<Superscript>2</Superscript>erverless
                            </ListTableCellRight>
                        </TableRow>
                        <TableRow>
                            <ListTableCellRight>
                                S<Superscript>3</Superscript>oftware&nbsp;as&nbsp;a
                            </ListTableCellRight>
                        </TableRow>
                        <TableRow>
                            <ListTableCellRight>
                                S<Superscript>4</Superscript>ervice
                            </ListTableCellRight>
                        </TableRow>
                        <TableRow>
                            <ListTableCellRight>
                                S<Superscript>5</Superscript>tarter
                            </ListTableCellRight>
                        </TableRow>
                        <TableRow>
                            <ListTableCellRight>Pack</ListTableCellRight>
                        </TableRow>
                    </TableBody>
                </Table>
            </ListBox>
            <Background />
        </ProductHeroLayout>
    );
}

export default ProductHero;
