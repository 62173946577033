import { createSlice } from '@reduxjs/toolkit';

// initial state
const initialState = {
    clientSecret: '',
    selectedPlan: {
        id: 'dev_Free',
        title: 'Free',
        price: 0,
        statementDescriptor: 'Default Free Plan',
        totalUsers: 1,
        featureList: 'One User, Many Requests'
    },
    updatedPlanSnackBar: '',
    planUpdateNotificationMessage: '',
    trialPeriodExpired: false
};

// ==============================|| SLICE - MENU ||============================== //

const planSlice = createSlice({
    name: 'plan',
    initialState,
    reducers: {
        selectPlan(state, selectedPlan) {
            state.selectedPlan = selectedPlan;
        },

        setTrialPeriodExpired(state, trialPeriodExpired) {
            state.trialPeriodExpired = trialPeriodExpired;
        },

        getTrialPeriodExpired(state) {
            return state.trialPeriodExpired;
        },
        setClientSecret(state, clientSecret) {
            state.clientSecret = clientSecret.payload;
        },

        getClientSecret(state) {
            return state.clientSecret;
        }
    }
});

export default planSlice.reducer;

export const { selectPlan, setTrialPeriodExpired, getTrialPeriodExpired, setClientSecret, getClientSecret } = planSlice.actions;
